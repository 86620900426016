import React, { useState, useEffect } from "react";
import Table from "../../../table";
import { useAuth } from "../../../../context/auth-context";
import { useAsync } from "../../../../utils/use-async";
import exportFromJSON from "export-from-json";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Spin, message } from "antd";
import { client } from "../../../../utils/api-client";
import { useHistory } from "react-router-dom";
import { Spinner } from "../../../lib";

const roleOption = [
  {
    label: "AUTHOR",
    value: "AUTHOR",
  },
  {
    label: "REVIEWER",
    value: "REVIEWER",
  },
  {
    label: "REVIEWER-2",
    value: "REVIEWER-2",
  },
  {
    label: "UPLOADER",
    value: "UPLOADER",
  },
];

const TargetReport = ({ type }) => {
  const { run, isLoading, data, isError, error } = useAsync();
  const { user } = useAuth();
  const [departments, setDepartment] = useState([]);
  const [countData, setCountData] = useState([]);
  const [dep, setDep] = useState("");
  const [users, setUsers] = useState([]);
  const [roleData, setRole] = useState("");
  const [fromDate, setFrom] = useState(new Date());
  const [toDate, setTo] = useState(new Date());
  const [rec, setRec] = useState([]);
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState("");
  const [project, setProject] = useState("");
  const [allProject, setProjectsData] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [form] = Form.useForm();
  const [projects, setProjects] = useState([]);
  const [totalDelta, setTotalDelta] = useState(0);
  const submitData = async (val) => {
    const roles = Array.isArray(roleData) ? roleData : [roleData];
    const payload = { ...val, roleData: roles };
    if (user.roleData.includes("MANAGER") && type === "team") {
      payload.manager = user._id;
    }

    try {
      await run(
        client("user/target/report", {
          body: { ...payload, type },
          method: "POST",
        })
      );
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  useEffect(() => {
    if (data?.message === "Fetched departments") {
      setDepartment(data?.departments);
    } else if (data?.message === "Fetched Report Users") {
      setUsers(data?.users);
    } else if (data?.message === "Fetched Report Projects") {
      setRec(data?.records);
      setProjectsData(data?.projectData);
      setCountData(data?.count);
      const totalD = data.records.reduce(
        (acc, record) => acc + parseFloat(record.delta),
        0
      );
      setTotalDelta(totalD);
    } else if (data?.message === "Fetched managers") {
      setManagers(data.data);
    }
  }, [data]);
  const fetchDepartments = async () => {
    await run(client("department/all/report"));
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const data = await run(
        client("user/target/report/fetch/project", {
          method: "GET",
        })
      );
      const arr = Object.values(data);
      if (Array.isArray(arr[1])) {
        setProjects(arr[1]);
      } else {
        setProjects([]);
      }
    };

    fetchProjects();
  }, [run]);

  const fetchManagers = async () => await run(client("admin/managers"));

  const fetchUsers = async (role, department) => {
    await run(client(`user/details/${department}/${role}`));
  };

  useEffect(() => {
    fetchDepartments();
    if (type === "team") {
      fetchManagers();
    }
  }, []);

  // useEffect(() => {
  //   if (isError) {
  //     message.error(error.message);
  //   }
  // }, [error]);

  const setDepChange = (e) => {
    setDep(e);
    if (roleData !== "") {
      fetchUsers(roleData, e);
    }
  };

  const setManagerChange = (e) => {
    setManager(e);
  };

  const projectChange = (e) => {
    setProject(e);
  };

  const fromDateChange = (e) => {
    setFrom(e.target.value);
  };

  const toDateChange = (e) => {
    setTo(e.target.value);
  };

  const setRoleChange = (e) => {
    setRole(e);
    if (dep !== "") {
      fetchUsers(e, dep);
    }
  };

  useEffect(() => {
    if (rec.length > 0) {
      setCols(rec);
    }
  }, [rec]);

  const setCols = (rows) => {
    if (rows.length === 0) {
      setColumns([]);
      return;
    }
    const desiredColumns = [
      "dateKey",
      "projects",
      "totalProductivityHours",
      "Qualitative Task ",
      "Demand Hours",
      "Ticket Demand",
      "Ticket Created",
      "Delta",
    ];
    const cols = desiredColumns.map((key) => ({
      title: key.charAt(0).toUpperCase() + key.slice(1).replace("_", "  "),
      dataIndex: key,
      key: key,
    }));

    setColumns(cols);
  };
  const preprocessData = (data) => {
    const processedData = [];

    data.forEach((item) => {
      const existingItemIndex = processedData.findIndex(
        (processedItem) => processedItem.dateKey === item.dateKey
      );

      if (existingItemIndex === -1) {
        processedData.push({
          dateKey: item.dateKey,
          projects: item.projects,
          totalProductivityHours: item.totalProductivityHours,
          demandHours: item.demandHours,
          ticketDemand: item.ticketDemand,
          ticketused: item.ticketused,
          leaveHours: item.leaveHours,
          qualitativeRecords: item.qualitativeHours,
          delta: item.delta,
          totalDemandHours: item.totalDemandHours,
          color: item.color,
        });
      } else {
        processedData[existingItemIndex].projects.push(...item.projects);
        // Calculate total productivity for combined projects
        processedData[existingItemIndex].totalProductivityHours += parseFloat(
          item.totalProductivityHours
        );
      }
    });

    return processedData;
  };
  const preprocessedData = preprocessData(rec);
  const defaultExpandedRowKeys = preprocessedData.map((item) => item.dateKey);
  const handlePrint = () => {
    const dataToDownload = [];
    preprocessedData.forEach((record) => {
      dataToDownload.push({
        Date: record.dateKey,
        "Total Productivity Hours": record.totalProductivityHours,
        "Shift Hours": record.demandHours,
        'Ticket Hours': record.ticketDemand,
        'Leave Hours': record.leaveHours,
        'totalDemandHours': record.totalDemandHours,
        'Delta': record.delta,
      });
      record.projects.forEach((project) => {
        dataToDownload.push({
          Date: record.dateKey,
          "Project Name": project.projectName,
          Subproject: project.subProject,
          Department: project.department,
          Subject: project.subject,
          Done: project.done,
          Target: project.target,
          shiftHours: project.demandHours,

          "Productivity Hours": project.productivityHours,
        });
      });
    });
    const fileName = "table_data";
    const exportType = "xls";
    exportFromJSON({ data: dataToDownload, fileName, exportType });
  };
  const backgroundColor = totalDelta >= 0 ? "#1CAC78" : "#FFA07A";

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "grid",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginBottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1>Target Report</h1>
          {rec.length !== 0 && (
            <div style={{ marginBottom: "1rem" }}>
              <h2>Target Report of:{}</h2>
            </div>
          )}
          <div>
            <div style={{ display: "flex", marginBottom: "1rem", gap: "1rem" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#FFA07A",
                    marginRight: "0.5rem",
                  }}
                ></div>
                <span>Non working day</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#85c6ff",
                    marginRight: "0.5rem",
                  }}
                ></div>
                <span>Working Days</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#fa9e95",
                    marginRight: "0.5rem",
                  }}
                ></div>
                <span>Ticket Used</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#c1f6f7",
                    marginRight: "0.5rem",
                  }}
                ></div>
                <span>Ticket Created</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#FFC72C",
                    marginRight: "0.5rem",
                  }}
                ></div>
                <span>Leave</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#1CAC78",
                    marginRight: "0.5rem",
                  }}
                ></div>
                <span>Incentive</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#FFA07A",
                    marginRight: "0.5rem",
                  }}
                ></div>
                <span>Loss</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          {rec.length !== 0 && (
            <>
              <Button icon={<DownloadOutlined />} onClick={() => handlePrint()}>
                Download Report
              </Button>

              {type === "department" && (
                <Button
                  onClick={() => setModal(true)}
                  style={{ marginLeft: "0.5rem" }}
                >
                  Count View
                </Button>
              )}
            </>
          )}
        </div>
        <div style={{ marginLeft: "10px", width: "100%" }}>
          <Form
            style={{ display: "grid", marginTop: "2rem" }}
            onFinish={submitData}
          >
            <div style={{ display: "flex", width: "auto", gap: "3rem" }}>
              {type === "team" && user.roleData[0] === "ADMIN" && (
                <Form.Item
                  label="Select Managers"
                  name="manager"
                  style={{ width: "20%" }}
                >
                  <Select
                    placeholder="Managers"
                    onChange={(e) => setManagerChange(e)}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {managers.map((e) => (
                      <Select.Option key={e._id} value={e._id}>
                        {e.fullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {
                <Form.Item
                  label="Select Department"
                  name="dep"
                  style={{ width: "20%" }}
                >
                  <Select
                    placeholder="Department"
                    onChange={(e) => setDepChange(e)}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {departments.map((e) => (
                      <Select.Option key={e._id} value={e._id}>
                        {e.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              }
              <Form.Item
                label="Select Role"
                name="roleData"
                style={{ width: "15%" }}
              >
                <Select
                  placeholder="Role"
                  onChange={setRoleChange}
                  showSearch={true}
                  mode={type === "user" ? "multiple" : ""}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {roleOption.map((e) => (
                    <Select.Option key={e.value} value={e.value}>
                      {e.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Select User"
                name="user"
                style={{ width: "20%" }}
              >
                <Select
                  placeholder="Users"
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {users.map((e) => (
                    <Select.Option key={e._id} value={e.email}>
                      {e.email}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div style={{ display: "flex", gap: "3rem", width: "auto" }}>
              <Form.Item label="From" name="fromDate" onChange={fromDateChange}>
                <Input type="date" />
              </Form.Item>
              <Form.Item label="To" name="toDate" onChange={toDateChange}>
                <Input type="date" />
              </Form.Item>
              <Form.Item
                label="Select Projects"
                name="project"
                style={{ width: "20%", marginLeft: "2rem" }}
              >
                <Select
                  placeholder="Projects"
                  showSearch={true}
                  onChange={projectChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {projects?.map((project) => (
                    <Select.Option key={project} value={project}>
                      {project}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="link" htmlType="submit">
                  {isLoading ? <Spinner /> : "Submit"}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
          width: "auto",
        }}
      >
        <div> Total Rows:{countData}</div>
        <div
          style={{
            padding: "10px",
            border: "1px solid black",
            borderRadius: "5px",
            backgroundColor,
          }}
        >
          <div style={{ color: "white", fontWeight: "bold" }}>
            Total Delta: {totalDelta.toFixed(3)}
          </div>
        </div>{" "}
      </div>
      <Table
        style={{
          border: "1px solid #e8e8e8",
          borderRadius: "5px",
          overflow: "hidden",
        }}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "even-row" : "odd-row"
        }
        pagination={false}
        bordered
        loading={usersLoading}
        dataSource={preprocessedData} // Use dataSource instead of data
        columns={[
          {
            title: "Date",
            dataIndex: "dateKey",
            key: "dateKey",
            width: 100,
          },
          {
            title: "Total Productivity Hours",
            dataIndex: "totalProductivityHours",
            key: "totalProductivityHours",
            width: 100,
          },
          {
            title: "Demand Hours",
            dataIndex: "demandHours",
            key: "demandHours",
            width: 60,
          },
          {
            title: "Ticket Hours",
            dataIndex: "ticketDemand",
            key: "ticketDemand",
            width: 60,
            render: (text, record) => (
              <div style={{ backgroundColor: record.color, padding: "8px" }}>
                {text}
              </div>
            ),
          },
          {
            title: "Qualitative Task",
            dataIndex: "qualitativeRecords",
            key: "qualitativeRecords",
            width: 100,
          },
          {
            title: "Leave Hours",
            dataIndex: "leaveHours",

            key: "leaveHours",
            width: 60,
            render: (text, record) => (
              <div>
                {text && (
                  <div style={{ backgroundColor: "#FFC72C", padding: "8px" }}>
                    {text}
                  </div>
                )}
                {/* {text} */}
              </div>
            ),
          },
          {
            title: "Total Demand Hours",
            dataIndex: "totalDemandHours",
            dataIndex: "totalDemandHours",
            key: "ticketused",
            width: 100,
          },
          {
            title: "Delta",
            dataIndex: "delta",
            key: "delta",
            width: 60,
            render: (text, record) => (
              <div>
                {text < 0 ? (
                  <div style={{ backgroundColor: "#ffa78d", padding: "8px" }}>
                    {text}
                  </div>
                ) : (
                  <div style={{ backgroundColor: "#4da79d", padding: "8px" }}>
                    {text}
                  </div>
                )}
                {/* {text} */}
              </div>
            ),
          },
        ]}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Table
                columns={[
                  {
                    title: "Project Name",
                    dataIndex: "projectName",
                    key: "projectName",
                  },
                  {
                    title: "Subproject",
                    dataIndex: "subProject",
                    key: "subProject",
                  },
                  {
                    title: "Department",
                    dataIndex: "department",
                    key: "department",
                  },
                  {
                    title: "Subject",
                    dataIndex: "subject",
                    key: "subject",
                  },
                  {
                    title: "Done",
                    dataIndex: "done",
                    key: "done",
                  },
                  {
                    title: "Target",
                    dataIndex: "target",
                    key: "target",
                  },
                  {
                    title: "Productivity Hours",
                    dataIndex: "productivityHours",
                    key: "productivityHours",
                  },
                ]}
                dataSource={record.projects}
                pagination={false}
                size="middle"
                rowKey={(row, index) => `${record.dateKey}-${index}`} // Unique key for sub-rows
              />
              <div
                style={{ float: "right", marginTop: "20px", marginRight: "5%" }}
              >
                <b>Total Productivity Hours: {record.totalProductivityHours}</b>
              </div>
            </>
          ),
          rowExpandable: (record) =>
            record.projects && record.projects.length > 0, // Only expandable if there are projects
        }}
        defaultExpandAllRows
        size="middle"
        rowKey="dateKey"
        defaultExpandedRowKeys={defaultExpandedRowKeys} // Expand all rows by default
      />
    </div>
  );
};

export default TargetReport;
